import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyD-jeB-wEhKjyu4KG7bW_1nGzbLQDoLFH8",
    authDomain: "shamaa-d9a6c.firebaseapp.com",
    databaseURL: "https://shamaa-d9a6c-default-rtdb.firebaseio.com",
    projectId: "shamaa-d9a6c",
    storageBucket: "shamaa-d9a6c.appspot.com",
    messagingSenderId: "1000233833380",
    appId: "1:1000233833380:web:e9ca763fc0306b429c6731"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, app};