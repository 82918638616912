// BoxesPage.js
import React, { useEffect, useState } from "react";
import Box from "./Box";
import { defineSnapshot, writeData } from "./database";
const BoxesPage = () => {
  const [boxes, setBoxes] = useState([]);
  const updateBoxValue = (tableName, newValue) => {
    const doc = boxes.find((box) => (box.id = tableName));
    doc.value = newValue ? Number(newValue) : 0;
    writeData(doc);
  };
  useEffect(() => {
    defineSnapshot(setBoxes);
  }, []);

  return (
    <div className="boxes-page">
      <div className="logo01">
        <h1>
          <a href="/">
            AL-SHAMA<span>Resturant</span>
          </a>
        </h1>
      </div>
      <hr />
      <div className="box-container">
        {/* Use updatedBoxValues to map over */}
        {boxes &&
          boxes
            ?.sort((a, b) => a.id - b.id)
            .map((box) => (
              <div key={box.id} className="box-and-text">
                {/* Pass updateBoxValue */}
                <Box
                  key={box.id}
                  id={box.id}
                  value={Number(box.value)}
                  updateBoxValue={updateBoxValue}
                />
                <span className="text">{`طاولة رقم ${box.id}`}</span>
              </div>
            ))}
      </div>
    </div>
  );
};
export default BoxesPage;
