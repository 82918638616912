// App.js

import React,{ useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BoxesPage from './BoxesPage';
import TablePage from './TablePage';
import './App.css'; // Import CSS file

const App = () => {
  useEffect(() => {
    document.title = "Al_Shama restaurant";
  }, []);

  return (
      <Router>
          <Routes>
            <Route path="/" element={<BoxesPage />} />
            <Route path="/tables" element={<TablePage />} />
          </Routes>
      </Router>
  );
};

export default App;
