import { db } from "./firebase";
import {
  getDocs,
  updateDoc,
  collection,
  query,
  where,
    onSnapshot,
} from "firebase/firestore";

const getData = async () => {
  const tables = collection(db, "tables-prod");
  const data = await getDocs(query(tables));
  return data.docs.map((doc) => doc.data());
};

const writeData = async (doc) => {
  const data = {
    value: doc.value,
    id: doc.id,
  };

  const tablesRef = collection(db, "tables-prod");
  const snapshot = await getDocs(
    query(tablesRef, where("id", "==", doc.id))
  );
  snapshot.docs[0] && updateDoc(snapshot.docs[0].ref, data);
};

const defineSnapshot = (setBoxes) => {
    onSnapshot(collection(db, "tables-prod"), () => {
        getData().then((data) => {
          const values = data.map((doc) => doc);
          setBoxes(values);
        });
      });
    };
export { getData, writeData,defineSnapshot };
