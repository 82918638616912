// TablePage.js
import React from "react";
import { useEffect, useState } from "react";
import { defineSnapshot, writeData } from "./database";
import "./candle.css"
const TablePage = () => {
  const [boxes, setBoxes] = useState([]);
  useEffect(() => {
    defineSnapshot(setBoxes);
  }, []);

  const handleReset = (id) => {
    const doc = boxes.find((box) => (box.id = id));
    doc.value = 0;
    writeData(doc);
  };

  return (
    <div className="table-page">
      <div className="spec">
        <div className="header">
        <h3> مطعم الشمعة</h3>
        <div className="candle">
          <div className="wick">
            <div className="flame-intensity">
              <div className="flame"></div>
            </div>
          </div>
        </div>
        </div>
        <div className="ser-t">
          <b></b>
          <span>
            <i></i>
          </span>
          <b className="line"></b>
        </div>
      </div>
      <hr />
      <br />
      <table className="shrink-table">
        <thead>
          <tr>
            <th>الحالة</th>
            <th>الحساب</th>
            <th>طاولة</th>
          </tr>
        </thead>
        <tbody>
          {boxes &&
            boxes
              ?.sort((a, b) => a.id - b.id)
              .map((box) => (
                <tr
                  key={box.id}
                  className={box.value > 0 ? "non-zero-row" : "zero-row"}
                >
                  <td>
                    {box.value > 0 ? (
                      <button
                        className="pay-button"
                        onClick={() => handleReset(box.id)}
                      >
                        <p>ادخال دفعة</p>
                      </button>
                    ) : (
                      <p>مدفوع</p>
                    )}
                  </td>
                  <td>
                    <span className="dollar-symbol">₪</span>
                    {box.value ? box.value :  "0"}
                  </td>

                  <td>{`طاولة رقم ${box.id}`}</td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablePage;
