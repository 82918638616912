// Box.js
import React, { useEffect } from "react";
import { useState } from "react";
import './Box.css';
const Box = ({ id, value, updateBoxValue }) => {
  const [boxValue, setBoxValue] = useState(value);

  useEffect(() => {
    setBoxValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/^0+/, '') // Convert to string
    setBoxValue(value);
    updateBoxValue(id, value);
  };

  return (
    <div className="box">
      <div className="dollar-input-container">
      <span className="dollar-symbol">₪</span>
      <input type="number" className="number-input" value={boxValue ? Number(boxValue)  : 0} onChange={handleInputChange} />
      </div>
    </div>
  );
};

export default Box;
